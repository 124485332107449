@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Ysabeau+SC:wght@1;100;200;300;400;500;600;700;800;900;1000&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--backgroundColor);
  overflow-x: hidden;
  color: var(--fontPrimaryColor);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.link-styles {
  text-decoration: none;
  color: var(--fontPrimaryColor);
}

:root {
  --primaryColor: #4fc0d6;
  --primaryColorDark: #2ca0b8;
  --primaryColorLight: #73bbc9b6;
  --secondaryColor: #64a1fc;
  --primaryBackgroundColor: #ffffff;
  --secondaryBackgroundColor: #e6e6e6;
  --trBackgroundColor: #ffffff63;
  --fontPrimaryColor: #383838;
  --fontPrimaryColorOpp: #e7e7e7;
  --fontSecondaryColor: #7a7a7a;
  --scrollbarColor: #cacaca;
  --bgColor2: #d6d5d5;
  --shadowColor: #979797;
  --shadowColor1: #d8d8d8;
  --shadowColor2: #ececec;

  --backgroundGradient: radial-gradient(
    circle at 10% 45%,
    #88bbf1 2%,
    #ffffff 25%
  );
  --backgroundGradient2: radial-gradient(
      circle at 34% 65%,
      #b0ceee 1%,
      transparent 35%
    ),
    radial-gradient(circle at 90% 35%, #97c6f8 0%, transparent 15%),
    radial-gradient(circle at 80% 68%, #cc99ee 0%, transparent 18%);

  --backgroundGradient2M: radial-gradient(
      circle at 20% 65%,
      #b0ceee 1%,
      transparent 25%
    ),
    radial-gradient(circle at 80% 38%, #97c6f8 0%, transparent 20%),
    radial-gradient(circle at 50% 60%, #cc99ee 0%, transparent 18%);
  --backgroundGradient2S: radial-gradient(
      circle at 20% 65%,
      #b0ceee 1%,
      transparent 10%
    ),
    radial-gradient(circle at 80% 50%, #97c6f8 0%, transparent 10%),
    radial-gradient(circle at 50% 60%, #cc99ee 0%, transparent 14%);

  --backgroundGradient2T: radial-gradient(
      circle at 20% 65%,
      #b0ceeed8 1%,
      transparent 25%
    ),
    radial-gradient(circle at 80% 38%, #97c6f8e7 0%, transparent 20%),
    radial-gradient(circle at 50% 60%, #deb0fce3 0%, transparent 18%);

  --backgroundGradient3: radial-gradient(
    circle at 12% 60%,
    #a1c7f0 2%,
    #ffffff 13%
  );
  --backgroundGradient4: radial-gradient(
    circle at 90% 58%,
    #a4caf0 2%,
    #ffffff 13%
  );

  background: radial-gradient(circle at 3% 25%, #002853 0%, #040c18 25%);
  --gradientBackground: -webkit-linear-gradient(
    109.6deg,
    rgba(150, 50, 189, 0.753) 11.2%,
    rgb(233, 135, 122) 100.2%
  );
  --gradientBackground2: -webkit-linear-gradient(
    109.6deg,
    rgba(150, 50, 189, 0.753) 31.2%,
    rgb(57, 135, 236) 100.2%
  );
  --gradientBackgroundDarker: -webkit-linear-gradient(
    109.6deg,
    rgba(106, 17, 141, 0.753) 11.2%,
    rgb(189, 77, 63) 100.2%
  );
}
[data-theme="dark"] {
  --primaryColor: #73bbc9;
  --primaryColorLight: #73bbc9b6;
  --secondaryColor: #64a1fc;
  --primaryBackgroundColor: #030811;
  --secondaryBackgroundColor: #091527;
  --trBackgroundColor: #0308119c;
  --fontPrimaryColor: rgb(255, 255, 255);
  --fontPrimaryColorOpp: #070707;
  --fontSecondaryColor: rgb(190, 190, 190);
  --scrollbarColor: #040c18;
  --bgColor2: #142b4b;
  --shadowColor: #a0c2e78e;
  --shadowColor1: #38495c54;
  --shadowColor2: #0d1116;

  --backgroundGradient: radial-gradient(
    circle at 10% 45%,
    #002853 0%,
    #030811 25%
  );
  --backgroundGradient2: radial-gradient(
      circle at 34% 65%,
      #002853 1%,
      transparent 25%
    ),
    radial-gradient(circle at 90% 35%, #002853 0%, transparent 15%),
    radial-gradient(circle at 80% 68%, #2b0053 0%, transparent 18%);

  --backgroundGradient2M: radial-gradient(
      circle at 20% 65%,
      #002853 1%,
      transparent 25%
    ),
    radial-gradient(circle at 80% 38%, #002853 0%, transparent 20%),
    radial-gradient(circle at 50% 60%, #2b0053 0%, transparent 12%);
  --backgroundGradient2S: radial-gradient(
      circle at 20% 45%,
      #002853 1%,
      transparent 10%
    ),
    radial-gradient(circle at 80% 50%, #002853 0%, transparent 10%),
    radial-gradient(circle at 50% 55%, #2b0053 0%, transparent 14%);
  --backgroundGradient2T: radial-gradient(
      circle at 20% 65%,
      #002853 1%,
      transparent 25%
    ),
    radial-gradient(circle at 80% 38%, #002853 0%, transparent 20%),
    radial-gradient(circle at 50% 60%, #2b0053 0%, transparent 12%);

  --backgroundGradient3: radial-gradient(
    circle at 10% 60%,
    #002853 0%,
    #030811 3%
  );
  --backgroundGradient4: radial-gradient(
    circle at 90% 58%,
    #002853 0%,
    #030811 13%
  );
}

::-webkit-scrollbar {
  width: 0.7em;
}
body::-webkit-scrollbar-track {
  background: #040c18;
}
body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: white;
}
body::-webkit-scrollbar-thumb:hover {
  background: rgb(182, 182, 182);
}
